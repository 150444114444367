import React from 'react'
import useDrager from '../hook/useDrager'

const CircleElement = () => {
    useDrager('circle-element')
  return (
    <div id='circle-element' className='circle-element' style={{ zIndex:1000,}}></div>
  )
}

export default CircleElement