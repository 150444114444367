import React from "react";
import useDrager from "../hook/useDrager";

const VideoElement = () => {
  useDrager("video-element");
  return (
    <video id="video-element" controls className="draggable video" style={{ zIndex:1000,}}>
      <source src="/videos/earth-video.mp4" type="video/mp4" />
    </video>
  );
};

export default VideoElement;
