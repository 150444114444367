import React, { useState, useEffect } from 'react';
import useDrager from '../hook/useDrager';

const Input = ({ placeholderText }) => {
  const [droppedTexts, setDroppedTexts] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useDrager('input-box');

  const handleDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain');
    if (data) {
      const inputElement = document.getElementById('input-box');
      if (inputElement && event.target === inputElement) {
        inputElement.value = data;
        setInputValue(data); 
      } else {
        setDroppedTexts((prevTexts) => [...prevTexts, { text: data, x: event.clientX, y: event.clientY }]);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData('text/plain', event.target.value);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value); 
  };

  useEffect(() => {
    document.addEventListener('drop', handleDrop);
    document.addEventListener('dragover', handleDragOver);

    return () => {
      document.removeEventListener('drop', handleDrop);
      document.removeEventListener('dragover', handleDragOver);
    };
  }, []);

  return (
    <div>
      <input
        id='input-box'
        placeholder='Enter Your Name'
        className='draggable input-box'
        type='text'
        style={{ position: 'relative' }}
        draggable={false} 
        value={inputValue}
        onChange={handleInputChange}
        onDrop={handleDrop} 
        onSelect={(e) => {
          if (e.target.value && window.getSelection().toString()) {
            e.target.setAttribute('draggable', true);
          } else {
            e.target.setAttribute('draggable', false);
          }
        }}
        onDragStart={handleDragStart} 
      />
      {droppedTexts.map((item, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            top: `${item.y}px`,
            left: `${item.x}px`,
          }}
        >
          {item.text}
        </div>
      ))}
    </div>
  );
};

export default Input;
