import React from 'react'
import useDrager from '../hook/useDrager';

const SmileEmoji = () => {
  useDrager('smile-emoji');
  return (
    <div id='smile-emoji' className=' draggable smile-emoji' style={{ fontSize: '4rem',zIndex:1000,}}>
      😀
    </div>
  )
}

export default SmileEmoji;