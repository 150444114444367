import React from 'react'
import useDrager from '../hook/useDrager';

const GlassesEmoji = () => {
  useDrager('glasses-emoji');
  return (
    <div id='glasses-emoji' className=' draggable glasses-emoji' style={{ fontSize: '4rem',zIndex:1000 }}>
   😎
    </div>
  )
}

export default GlassesEmoji;