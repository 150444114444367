import React from 'react'
import useDrager from '../hook/useDrager';

const MaskEmoji = () => {
  useDrager('mask-emoji');
  return (
    <div id='mask-emoji' className=' draggable mask-emoji' style={{ fontSize: '4rem' }}>
    😷
    </div>
  )
}

export default MaskEmoji;