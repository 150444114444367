import React, { useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";

const TrashElement = () => {
  const [position, setPosition] = useState({ bottom: "5px", right: "-5px" });

  useEffect(() => {
    const handleScroll = () => {
      const bottomOffset =
        20 + window.innerHeight - document.documentElement.offsetHeight;
      const rightOffset =
        20 + window.innerWidth - document.documentElement.offsetWidth;
      setPosition({ bottom: bottomOffset + "px", right: rightOffset + "px" });
    };

    window.addEventListener("scroll", handleScroll);

    // Initial positioning
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDeleteConfirmation = () => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
    
      alert("Item deleted!"); 
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault(); 
    handleDeleteConfirmation();
  };

  return (
    <div
      id="trash"
      className="draggable trash"
      style={{
        position: "fixed",
        // bottom: position.bottom,
        // right: position.right,
        backgroundColor: "#758694",
        borderRadius: "10px 0 0 10px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        overflow: "visible",
        cursor: "pointer",
      }}
      onDragEnter={handleDragEnter} 
    >
      <FaTrashAlt fontSize={50} />
    </div>
  );
};

export default TrashElement;
