import React from 'react'
import useDrager from '../hook/useDrager'

const Word = () => {
    useDrager('word')
  return (
    <h1 id='word' className='word' style={{ zIndex:1000,}}>Hello</h1>
  )
}

export default Word