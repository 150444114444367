import { useEffect, useRef } from "react";

function useDrager(id) {
  const isClicked = useRef(false);
  const draggedElement = useRef(null);
  const coords = useRef({
    startX: 0,
    startY: 0,
    lastX: 0,
    lastY: 0,
  });

  useEffect(() => {
    const target = document.getElementById(id);
    if (!target) {
      console.error(`Element with id '${id}' not found.`);
      return;
    }

    const container = target.parentElement;
    if (!container) {
      console.error(`Parent container for element with id '${id}' not found.`);
      return;
    }

    const onMouseDown = (e, element) => {
      // Check if the clicked element is an image
      if (e.target.tagName === "IMG") {
        e.preventDefault(); // Prevent default behavior (text selection)
      }

      // Check if there's text selected
      const selection = window.getSelection().toString();
      if (selection) {
        return; // Prevent dragging if text is selected
      }

      isClicked.current = true;
      draggedElement.current = element;
      draggedElement.current.classList.add("move-cursor");
      coords.current.startX = e.clientX;
      coords.current.startY = e.clientY;
      coords.current.lastX = element.offsetLeft;
      coords.current.lastY = element.offsetTop;
    };

    const onMouseUp = () => {
      isClicked.current = false;
      if (draggedElement.current) {
        draggedElement.current.classList.remove("move-cursor");

        // Check if the dragged element is over the trash element
        const trashElement = document.getElementById("trash");
        if (trashElement) {
          const trashRect = trashElement.getBoundingClientRect();
          const draggedRect = draggedElement.current.getBoundingClientRect();

          if (
            draggedRect.left < trashRect.right &&
            draggedRect.right > trashRect.left &&
            draggedRect.top < trashRect.bottom &&
            draggedRect.bottom > trashRect.top
          ) {
            // Remove the dragged element from the DOM
            draggedElement.current.remove();
          }
        }
      }
      draggedElement.current = null;
    };

    const onMouseMove = (e) => {
      if (!isClicked.current || !draggedElement.current) return;

      const nextX = e.clientX - coords.current.startX + coords.current.lastX;
      const nextY = e.clientY - coords.current.startY + coords.current.lastY;

      draggedElement.current.style.top = `${nextY}px`;
      draggedElement.current.style.left = `${nextX}px`;
    };

    target.addEventListener("mousedown", (e) => onMouseDown(e, target));
    document.addEventListener("mouseup", onMouseUp);
    container.addEventListener("mousemove", onMouseMove);
    container.addEventListener("mouseleave", onMouseUp);

    return () => {
      target.removeEventListener("mousedown", (e) => onMouseDown(e, target));
      document.removeEventListener("mouseup", onMouseUp);
      container.removeEventListener("mousemove", onMouseMove);
      container.removeEventListener("mouseleave", onMouseUp);
    };
  }, [id]);
}

export default useDrager;
