import React from 'react'
import useDrager from '../hook/useDrager';

const SaluteEmoji = () => {
  useDrager('salute-emoji');
  return (
    <div id='salute-emoji' className=' draggable salute-emoji' style={{ fontSize: '4rem',zIndex:1000 }}>
🙄
    </div>
  )
}

export default SaluteEmoji;