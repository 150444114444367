import React, { useState, useEffect, useRef } from 'react';
import useDrager from '../hook/useDrager';

const UserNameInput = () => {
  const [droppedTexts, setDroppedTexts] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  useDrager('user-input');

  const handleDrop = (event) => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text/plain');
    if (data) {
      const inputElement = inputRef.current;
      if (inputElement && event.target === inputElement) {
        inputElement.value = data;
        setInputValue(data);
      } else {
        setDroppedTexts((prevTexts) => [
          ...prevTexts,
          { text: data, x: event.clientX, y: event.clientY },
        ]);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragStart = (event) => {
    event.dataTransfer.setData('text/plain', event.target.value);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('drop', handleDrop);
      inputElement.addEventListener('dragover', handleDragOver);

      return () => {
        inputElement.removeEventListener('drop', handleDrop);
        inputElement.removeEventListener('dragover', handleDragOver);
      };
    }
  }, []);

  return (
    <div>
      <input
        ref={inputRef}
        id='user-input'
        placeholder='Enter Your FullName'
        className='draggable input-box'
        type='text'
        style={{ position: 'relative' }}
        draggable={false} // Ensure this is false initially
        value={inputValue}
        onChange={handleInputChange}
        onDragStart={handleDragStart}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onSelect={(e) => {
          if (e.target.value && window.getSelection().toString()) {
            e.target.setAttribute('draggable', true);
          } else {
            e.target.setAttribute('draggable', false);
          }
        }}
      />
      {droppedTexts.map((item, index) => (
        <div
          key={index}
          style={{
            position: 'absolute',
            top: `${item.y}px`,
            left: `${item.x}px`,
            zIndex:1000,
          }}
        >
          {item.text}
        </div>
      ))}
    </div>
  );
};

export default UserNameInput;
