import React from 'react'
import useDrager from '../hook/useDrager'

const ImageElement = () => {
    useDrager('image-element')
  return (
    <img id='image-element' src='/images/nature.jpg' alt='draggable' className='draggable image' style={{ zIndex:1000,}} />

  )
}

export default ImageElement