import React from "react";
import useDrager from "../hook/useDrager";

const Pinkbox = () => {
  useDrager("pinkbox");
  return (
    <div
      className="draggable"
      id="pinkbox"
      style={{
        width: "100px",
        height: "100px",
        backgroundColor: "pink",
        position: "absolute",
        zIndex:1000,
      }}
    ></div>
  );
};

export default Pinkbox;
