import React from 'react'
import useDrager from '../hook/useDrager'

const Notification = () => {
    useDrager('notifications')
  return (
    <div id='notifications' className='draggable notifications' style={{ zIndex:1000,}}>
        <button type="button" class="btn btn-warning">
  Notifications <span class="badge bg-secondary">4</span>
</button>
    </div>
  )
}

export default Notification