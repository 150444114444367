import React from 'react';
import './App.css';
import Pinkbox from './components/Pinkbox';
import Input from './components/Input';
import ImageElement from './components/ImageElement';
import ImageCard from './components/ImageCard';
import VideoElement from './components/VideoElement';
import AudioElement from './components/AudioElement';
import CircleElement from './components/CircleElement';
import Star from './components/Star';
import Button from './components/Button';
import SmileEmoji from './components/SmileEmoji';
import Form from './components/Form';
import HeartEmoji from './components/HeartEmoji';
import WordElement from './components/WordElement';
import Notification from './components/Notification';
import TrashElement from './components/TrashElement';
import GlassesEmoji from './components/GlassesEmoji';
import SaluteEmoji from './components/SaluteEmoji';
import MaskEmoji from './components/MaskEmoji';
import Word from './components/Word';
import Globe from './components/Globe';
import UserNameInput from './components/UserNameInput';

function App() {
  return (
    <main className='retro-grid'>
      <div className="main-container">
        <Pinkbox />
        <Input placeholderText="Enter Your Name" />
        <UserNameInput placeholderText="Enter Your FullName" style={{backgrouncColor:'red'}}/>
        <ImageElement />
        <ImageCard />
        <VideoElement />
        {/* <AudioElement /> */}
        <CircleElement />
        <Star />
        <Button />
        <SmileEmoji />
        {/* <Form /> */}
        <HeartEmoji />
        <WordElement />
        <Notification />
        <TrashElement />
        <GlassesEmoji />
        <SaluteEmoji />
        <MaskEmoji />
        <Word />
        {/* <Globe/> */}
      </div>
    </main>
  );
}

export default App;
