import React from "react";
import useDrager from "../hook/useDrager";
import { FaStar } from "react-icons/fa";


const Toasts = () => {
    useDrager('star')
  return (
   <div id="star" className="star" style={{ zIndex:1000,}}>
    <FaStar  color="yellow" fontSize={100} />
    </div>
  );
};

export default Toasts;
