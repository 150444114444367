import React from 'react'
import useDrager from '../hook/useDrager';

const HeartEmoji = () => {
  useDrager('heart-emoji');
  return (
    <div id='heart-emoji' className='draggable heart-emoji' style={{ fontSize: '4rem',zIndex:1000 }}>
      ❤
    </div>
  )
}

export default HeartEmoji;