import React from 'react'
import useDrager from '../hook/useDrager'

const WordElement = () => {
    useDrager('word-element')
  return (
<h1 id='word-element' className='word-element' style={{ zIndex:1000,}}>JavaScript</h1>
  )
}

export default WordElement