import React from 'react'
import useDrager from '../hook/useDrager'

const Button = () => {
    useDrager("button")
  return (
   <button id='button' className='btn btn-danger' style={{ zIndex:1000,}}>Read Me</button>
  )
}

export default Button